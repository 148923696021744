import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppUserMenuService } from 'src/app/app-service/app-user-menu.service';
import { AppAccountSerivce } from 'src/app/app-service/auth/account.service';


@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    standalone: false
})
export class AppAccountComponent{
  @Output() isLogOut = new EventEmitter<boolean>();
  @Input() username = 'Guest';
  constructor(
    public menuService: AppUserMenuService) {}
  signOut() {
    this.isLogOut.emit(true);
  }
}
