import { Component, EventEmitter, Output } from '@angular/core';
import { ZzMenu, ZzMenuTree } from 'src/app/zz/zz-user/shared/zz-user.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class AppMenuComponent{
  items$: ZzMenuTree[] = [];
  @Output() menuOut = new EventEmitter();
  constructor() {}
  buttonClick(item: ZzMenu){
    this.menuOut.emit(item);
  }
  setItems(items: ZzMenuTree[]){
    if (items && items.length > 0){
      this.items$ = [...items];
    }
    else{
      this.items$ = [];
    }
  }
}
