import { Component, Input, Optional, effect } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';
import { Auth, signOut } from '@angular/fire/auth';
import { ZzMenu } from 'src/app/zz/zz-user/shared/zz-user.model';
import { AppMessageService } from 'src/app/app-service/app-message.service';
import { AppAccountSerivce } from 'src/app/app-service/auth/account.service';
import { AppUserMenuService } from 'src/app/app-service/app-user-menu.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: 'nav-bar.component.html',
    styleUrls: ['nav-bar.component.scss'],
    standalone: false
})
export class AppNavBarComponent {
  @Input() showLogin = false;
  imageUrl?: string = '';
  isOpen = false;

  //isLoggedIn$!: Observable<boolean>;
  constructor(
    public msgService: AppMessageService,
    public accountService: AppAccountSerivce,
    //public authService: AuthService,
    public menuService: AppUserMenuService,
    //public account: AppAccountSerivce,
    public dialog: MatDialog,
    private router: Router,
    @Optional() private auth: Auth
  ) {

  }
  signOut(event: any) {
    signOut(this.auth).then((rs: any)=>{
      this.accountService.setUserLogout();
      this.router.navigate(['/login']);
    });
  }
  goHome() {
    this.router.navigate(['/home']);
  }
  clickQuickMenu(item: ZzMenu) {
    this.router.navigate([item.routeLink]);
    this.isOpen = false;
  }
  openMenuDialog(){
    const dialogRef =this.dialog.open(MenuDialogComponent, {
      minWidth: '98vw',
      height: '95vh',
      data: {}
    });
    dialogRef.afterClosed().subscribe((rs: ZzMenu) => {
      if (rs && rs.routeLink){
        this.router.navigate([rs.routeLink]);
      }
    });

  }


}
