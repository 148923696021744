import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { StringUtilService } from 'src/app/app-shared/utils/string-util.service';
import { ZzMenu, ZzMenuTree } from 'src/app/zz/zz-user/shared/zz-user.model';

export const _filter = (opt: ZzMenu[], value: string): ZzMenu[] => {
  let filterValue = '';
  if (value && typeof(value)==='string'){
    filterValue = value.toLowerCase();
  }
  else{
    return [];
  }
  return opt.filter(item => item.mnuName!.includes(filterValue)
  || item.mnuId!.includes(filterValue)
  || item.mnuDescription?.includes(filterValue)
  || item.mnuSearchName?.includes(filterValue)
);
};
@Component({
    selector: 'app-menu-search',
    templateUrl: './menu-search.component.html',
    styleUrls: ['./menu-search.component.scss'],
    standalone: false
})
export class AppMenuSearchComponent{
  
  mnuGroups: ZzMenuTree[] = [];
  mnuGroupOptions: Observable<ZzMenuTree[]> | undefined
  @Output() menuOut = new EventEmitter();
  stateForm = this._formBuilder.group({
    stateGroup: '',
  });

  constructor(private _formBuilder: FormBuilder,
    private stringUtilService: StringUtilService
  ){
    
  }
  buttonClick(item: ZzMenu){
    this.menuOut.emit(item);
  }
  setItems(items: ZzMenuTree[]){
    if (items && items.length > 0){
      items.forEach(item=>{
        if (item.childMenus && item.childMenus.length > 0){
          item.childMenus.forEach(mnuItem=>{
            mnuItem.mnuSearchName = this.stringUtilService.convertVNKhongDau(mnuItem.mnuName!);
          });
        }
      })
      this.mnuGroups = [...items];
    }
    else{
      this.mnuGroups = [];
    }
    //Sau khi set rồi mới thực hiện filter
    this.mnuGroupOptions = this.stateForm.get('stateGroup')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );
  }
  selectItem(event: any){
    var refItem: ZzMenu = event.option.value;
    if (refItem){
      this.menuOut.emit(refItem);
    }
  }
  private _filterGroup(value: string): ZzMenuTree[] {
    if (value) {
      return this.mnuGroups
        .map(group => ({mgrItem: group.mgrItem, childMenus: _filter(group.childMenus, value)}))
        .filter(group => group.childMenus.length > 0);
    }

    return this.mnuGroups;
  }
}
