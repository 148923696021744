import { Component} from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { AppAccountSerivce } from '../app-service/auth/account.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent {

  constructor(
    public readonly firebaseApp: FirebaseApp,
    public readonly accountService: AppAccountSerivce
  ) {


  }
}
